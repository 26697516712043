#sidenav {
    min-width: 250px;
    max-width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -250px;
    transition: all 0.5s;
    /* top layer */
    z-index: 9999;
    background-color: white;

    #sidenav-header,
    #sidenav-body,
    #sidenav-contact {
        border-bottom: 1px solid $dairyland-concrete;
    }

    #sidenav-contact {
        font-weight: bold;

        a {
            color: $primary;
            font-size: 1rem;
        }
        
        .referral-agency.referral-agency-contact {
            margin-left: 0;
            color: $primary;
            font-size: 1rem;
            padding-left: .25rem;

            .referral-agency-name {
                font-size: 1rem;
                padding-top: .25rem;
            }
        }
    }

    .sidenav-item {
		&:not(.active,.complete) {
			pointer-events:none;
		}
        * {
            color: $secondary;
        }
        opacity: .4;
        overflow: hidden;

        .sidenav-icon:before {
            content: "";
            font-family: "DairylandIcons";
        }
        
        .quote-sidenav-title-container {
            a {
                &.inactive {
                    pointer-events: none;
                }
            }

            .sidenav-entry-list {
                li {
                    padding-bottom: $sidenav-vehicle-padding;
                }
            }
        }

        &.complete {
            * {
                color: inherit;
            }
            opacity: 1;

            .sidenav-icon:before {
                content: "\e926";
            }
        }

        &.active {
            opacity: 1;

            .sidenav-icon:before {
                content: "\e967";
            }
        }
    }
    .sidenav-number {
        min-width: 1rem;
    }

    &.active {
        left: 0;
    }
}



#overlay {
    position: fixed;
    /* full screen */
    width: 100vw;
    height: 100vh;
    top: 0;
    /* transparent black */
    background-color: black;
    /* appears just below the sidebar */
    z-index: 9998;
    opacity: 0;
    display: none;

    /* display .overlay when it has the .active class */
    &.active {
        opacity: 0.5;
        display: block;
    }
}