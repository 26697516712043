.faq-modal {
	
	padding-bottom: 50px;

    .phone-number-container {
        justify-content: center;
        a {
            text-decoration: none;
        }
        .faq-phone-number-button {
            padding: $faq-phone-number-TB-padding $faq-phone-number-LR-padding $faq-phone-number-TB-padding
                $faq-phone-number-LR-padding;
            border-radius: 5px;
            border: none;
            color: $faq-contact-us-color;
            box-shadow: 0 0 4px 0px $secondary, 0 0 4px 0px $secondary;

            .icon-contact {
                font-size: $faq-phone-icon-size;
            }

            .faq-phone-number-text {
                font-size: $faq-phone-number-size;
            }
        }
    }


    .referral-agency-contact {
        margin: unset;
        justify-content: center;

        .referral-agency-name {
            margin-left: 0;
            margin-right: 0;
            padding-top: 15px;
            color: $primary;
            font-size: 1.25rem;

            &:empty {
                padding-top: 0;
            }
        }
    }

    .icon-close {
        font-size: $iq-faq-close-size;
        padding: $iq-faq-close-padding;
        position: absolute;
        top: 0;
        right: 0;
    }
    .questions-container {
        padding-top: $faq-questions-padding;
    }
    .contact-help-container {
        padding-left: $iq-faq-contact-help-padding;
        padding-right: $iq-faq-contact-help-padding;
    }

    .faq-modal-title {
        margin: 0;
        padding-top: 15px;
        padding-bottom: 15px;
        color: $dairyland-indigo;
        background-color: $white;
        justify-content: center;
    }
    .faq-header {
        .faq-title {
            margin: 0;
            padding-top: $faq-title-top-padding;
            padding-bottom: $faq-title-bottom-padding;
            color: $dairyland-indigo;
            background-color: $white;
            justify-content: center;
        }
    }

    .accordion {
        border-bottom: $faq-card-border;
    }

    .faq-card {
        border-top: $faq-card-border;
        .card-header {
            background-color: $white;
            margin: 0;
            border-bottom: none;
            padding-left: 0;

            .question {
                white-space: normal;
                margin-bottom: 0;
            }

            .icon-rightchevron {
                display: none;
            }

            .icon-downchevron {
                display: inline-block;
                font-size: $faq-down-chevron-size;
            }

            .faq-button {
                @extend .typography-body-copy-medium;
                text-decoration: none;
                line-height: 20px;
                color: $gray-900;
                padding-left: 0;
            }
            .collapsed {
                .icon-rightchevron {
                    display: inline-block;
                }
            }

            .collapsed {
                .icon-downchevron {
                    display: none;
                }
            }

            .faq-button {
                @extend .typography-body-copy-medium;
                text-decoration: none;
                line-height: 20px;
                color: $black;
                padding-left: 0;

                .icon-container {
                    display: flex;
                    align-items: center;
                    span {
                        width: 100%;
                        text-align: right;
                        align-self: center;
                    }
                }

                col {
                    padding-left: 0;
                }
            }
        }

        .contact-help-container {
            padding-left: $iq-faq-contact-help-padding;
            padding-right: $iq-faq-contact-help-padding;

            .faq-modal-title {
                margin: 0;
                padding-top: 15px;
                padding-bottom: 15px;
                color: $dairyland-indigo;
                background-color: $white;
                justify-content: center;
            }
        }
    }
}

.omni-container {
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;

    .fa {
        color: $dairyland-indigo;
        font-size: 3rem;
        margin-bottom: 15px;
    }
}

button.faq-close-button {
    font-size: $iq-faq-close-size;
    padding: $iq-faq-close-padding;
    position: absolute;
    top: 0;
    right: 0;
}
