@import "../variables/vehicleone.variables.scss";
#quote-vehicleone-layout-form {

    .disclaimer-container {

        a {
            display: inline-block;
        }

        h2 {
            margin-top: $disclaimer-container-margin-top;
            padding-bottom: $disclaimer-container-padding-bottom;
            padding-top: $disclaimer-container-padding-top;
        }
    }

    .effective-date-container,
    .displacement-group,
    .electricBike-group,
    .vehicle-input-group.electricBikePower-group {

        h2 {
            font-family: $effective-date-container-h4-font-family;
            font-weight: $effective-date-container-h4-font-weight;
            font-size: $effective-date-container-h4-font-size;
			padding-top: $effective-date-container-h4-padding-top;
        }
    }
}