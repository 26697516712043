//
// Quote Find Agent Fumctionality
//-------------------------------
#find-agent-container {

	padding-left: 	0;
	padding-right: 	0;

	label {
		color: $label-active-color;
	}
	
	.find-an-agent-info {
		color: $formheader-color;
	}

	.hanging-paragraph {
		text-indent: -$hanging-paragraph;
		padding-left: $hanging-paragraph;
	}
	
	.hanging-paragraph-below-text {
		padding-left: $hanging-paragraph;
	}

	.map-wrapper {
		height: 375px;
		margin: 15px 0 30px 0;

		.map-canvas {
			width: 100%;
			height: 100%;
		}
	}
	
	.find-agent-disclaimer {
		font-family: $font-family-regular;
		font-size: 0.75rem;
		line-height: 1rem;
		color: $formheader-color;
		font-weight: 600;
		a {
			text-decoration: none;
		}
	}
	
	#find-agent-results-container {		

		margin-top: 30px;
		
		@include media-breakpoint-up(lg) {
            margin-top: 15px;
        }

		.agent-list {
			@include media-breakpoint-up(md) {		
				max-height: 375px;
				overflow-y: auto;
                overflow-x: hidden;
                padding-right: 15px;
    		}
    		
    		.agent-listing {
    			line-height: 1.5rem;
    		}
    		
    		h4 {
    			font-family: $font-family-semi-bold;
    		}
    		
    		.agent-listing-extra-info {
    			color: $black;
    			padding-bottom: 0.5rem;
    			margin-bottom: none;
    			font-size: 0.9375rem;
    			
    			.omni-agent-info {
					padding-bottom: 1rem;
					display: none;
					a {
						text-decoration: none;
						font-size: 16px;
						display: block;
					}
    			}
    		}
    		
    		.send-omni-quote-button-container {
				    
				    .send-omni-quote {
                        float: right;

                        @include media-breakpoint-down(sm){
                            padding-left: 6px;
                            padding-right: 6px;
                        }
				    }
				    
				    .successful-send-omni-quote {
						display: none;
				    	padding-top: 0.5rem;
				    	color: $brand-success;
				    	i {
				    		font-size: 2rem;
				    	}
				    	
				    	p {
				    		padding-bottom: 0;
				    		margin-bottom: 0;
				    	}
				    	
				    	.send-omni-quote-success-title {
				    		font-family: $font-family-semi-bold;
				    		
				    	}
                    }
						
						.errors-list {
							li {
								display: none;
								color: $state-danger-text;
							}
						}
			}
			
			.selected-agent {
				.successful-send-omni-quote {
                    float: right;
                    max-width: 145px;
                    display: block;
				}

				.btn.send-omni-quote {
					display: none;
				}
				
				.agent-listing-extra-info {
					.omni-agent-info {
						display: block;
	    			}
	    		}
			}

			// Show error messages when parent container has has-error class
			.has-error .errors-list {
				li {
					display: inline-block;
				}
			}
		}
	}

	.find-agent-cancel {
		min-width: 100px;
	}
}