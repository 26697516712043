.post-bind {

    .btn-show-summary {
        display: initial;
    }
}

body.quote .customer-quote-content .quote-container .final-sale-section h2 {
    @extend %typography-h2;
}

.finalsale-container {

    .btn-group-toggle {

        .fancy-options-column {

            label {
                padding-left: 0;

                &:before {
                    content: "";
                }
            }
        }
    }
}