.garaging-standardization {
	h3 {
		color: $dairyland-asphalt;
		margin-bottom: 20px;
	}
	address {
		margin-bottom: 0;
		word-break: break-word;
	}
}
