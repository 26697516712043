$summary-card-margin-bottom: 15px;
$summary-card-body-padding: 20px 10px;
$summary-delete-icon-color: $secondary;
$summary-entity-icon-color: $primary;
$summary-entity-font-size: 2.5rem;
$summary-entity-min-width: 4.0rem;
$summary-entity-margin-right: 10px;
$summary-entity-text-align: center;
$summary-add-icon-color: $secondary;
$summary-add-icon-min-width: 3rem;
$summary-add-icon-margin-right: 0;
$summary-edit-entity-card-border-color: transparent;
$summary-edit-entity-card-box-shadow: 0px 0px 3px #AAA;
$summary-add-entity-card-border-style: dashed;
$summary-h1-margin-bottom: 20px;
$summary-edit-entity-card-a-color: $dairyland-asphalt;
$summary-edit-entity-card-delete-icon-font-size: 1.25rem;
$summary-edit-entity-card-body-padding-right: 40px;
$summary-edit-entity-card-body-background: transparent;
$summary-edit-entity-card-border-width: 0px;
$summary-edit-entity-card-body-border: none;
$summary-entity-card-outline-focus: 5px auto $secondary;

$summary-missing-info-entity-card-color: $danger;
$summary-missing-info-entity-card-box-shadow: 0px 0px 3px $summary-missing-info-entity-card-color;
