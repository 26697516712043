#quote-header-affix {
    color: #D8D8D8;
    font-weight: bold;
    text-align: center;
    background: $primary;

    #progress-bar-categories-container {
        text-transform: capitalize;
    }

    .active {
        color: $primary;
        background: $dairyland-lightdusk;
    }

    .progress {
        height: 8px;
    }

    .progress-bar {
        background: $secondary;
    }

    .col {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
}