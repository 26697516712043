/**
  * Dairyland Fancy Options Overrides
  */


//
// Final Sale Bill Plans Column Definitions
// -------------------------------
.fancy-options-column {

	&.fancy-options-column-1 {
		@include make-fancy-column(2, 2, 1);
	}
    
	&.fancy-options-column-2 {
		@include make-fancy-column(10, 10, 11);

		h3, .h3 {
			display: flex;
			flex-direction: column;
			justify-content: center;
            text-align: left;
		}
	}
}

.fancy-options-list-group {
    
	.fancy-options-list-group-item {
        display: block;
    }
}
