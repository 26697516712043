//Lock the tooltip icon to top-left
.tooltip-and-required .icon-QTE-tooltip:before {
    position: absolute;
    top: 0;
    left: 0;
    color: $dairyland-indigo;
}

// Positioned it correctly based off icon
a.page-tooltip {
    position: absolute;
    top: 12px;
    display: block;
    width: 20px;
    height: 20px;
    font-size: 0.9em;
}

a.page-tooltip.icon-questionmark {
    position: absolute;
    top: 0px;
    display: block;
    width: 20px;
    height: 20px;
    font-size: 2em;
}

.tooltip-and-required .questionmark:before {
    color: $dairyland-indigo;
}

.AutomaticPaymentDraftDate-container .tooltip-and-required {
	left: unset;
}

.popover {
    background-color: white;
    border: none;
    box-shadow: 0 0px 5px 1px rgba(54,29,93,0.6);
    max-width: 100%;
    max-height: 250px;
    padding-top: 5px;
    padding-bottom: 5px;
    z-index: 1031; // Popover should display over header

    &.bs-popover-top .arrow::before{
        bottom: 5px;
        border-top-color: white;
        border-bottom-color: white;
    }

    &.bs-popover-top .arrow::after {
        bottom: 5px;
        border-top-color: white;
        border-bottom-color: white;
    }

    &.bs-popover-bottom .arrow::before{
        top: 5px;
        border-top-color: white;
        border-bottom-color: white;
    }

    &.bs-popover-bottom .arrow::after {
        top: 5px;
        border-top-color: white;
        border-bottom-color: white;
    }

    .popover-body {
        max-height: 240px;
        padding: 4px 9px;
        color: $dairyland-indigo;
        border: none;
        overflow-x: hidden;
        overflow-y: auto;

        .btn-close-tooltip {
            font-size: 8px;
        }

        .tooltip-msg {
            @extend .typography-tooltip-copy;
        }
    }
}

.label-tooltip {
    .tooltip-and-required {
        position: static;

        a {
            position: static;
            display: inline;

            &:before {

                position: static;
            }
        }
    }
}

.hide-tooltips {
    .tooltip-and-required {
        display: none !important;
    }
}