//
// Navigation
// --------------------------------------------------

// navbar contains top navigation and top search (by name, policy, claim number...)
.iq-navbar {
    max-height: $navbar-max-height-mobile;
    transition: $navbar-transition;

    /* Unable to extend BS4 .fixed-top because you may not @extend 
    an outer selector from within @media. You may only @extend 
    selectors within the same directive. */
    background-color: $iq-navbar-bg;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
	border-bottom: 1px solid $primary;	
	padding-top: $iq-navbar-padding;
    padding-bottom: $iq-navbar-padding;
	
	// just top navigation container	
	.navbar-main {
	
		width: 100%;

        @include media-breakpoint-up(md) {
            display: flex;

            * {
                vertical-align: middle;
            }

            .save-and-exit img {
                /* The Dairyland logo is not visually centered despite being so technically, so we fudge it's margin to appear centered*/
                margin-bottom: -8px;
            }

            .btn-iq-help,
            .btn-iq-phone {
                span {
                    font-size: 1rem;
                }
            }
        }
		
		.navbar-main-content {
			
			a {
			
				&.btn-iq-phone,
				&.btn-iq-help,
				&.btn-iq-edit {
					color : $iq-navbar-button-color;
                    font-size: $iq-navbar-button-font-size;
                    padding: $iq-navbar-button-padding-xs;
				}
			}
					
			div {

				&.quote-phone-number {
					display: inline-block;
				}

				&.bind-phone-number {
					display: none;
				}
			}
		}
	}
	
	// unordered list container for the navigation list items
	.navbar-nav {
		width: 100%;
		
		.fa-stack{
			margin: 0 auto;
		}
		
		// Navigation Items (Quote, Billing, Policies, Claims, Reports)
		> li {
				
			.nav-label {
				text-transform: uppercase;
				font-size: 1.65em;
				vertical-align: middle
			}
				
			> a {
				padding:		2px;
				text-align: 	left;
				width: 			100%;
				
				i {

					&.fa-stack-1x {
						color: $fontcolor-brand-primary-bg;
					}
				}
				
				> img {
				
					&.img-responsive {
						max-width: 100%;
					}
				}
			}
		}
	}	
}

body {
	padding-top: $navbar-max-height-mobile;
	
	&.post-bind {
	
		.navbar {
			
			// just top navigation container	
			.navbar-main {
				
				.navbar-main-content{
					div {
						&.quote-phone-number {
						
							display: none;
						}
					
						&.bind-phone-number {
						
							display: inline-block;
						}
					}
				}
			}
		}
	}

	&.bridged-quote {
		.navbar {
			// just top navigation container	
			.navbar-main {
				.navbar-main-content{
					.btn-iq-help {
						display: none;
					}
				}
			}
		}
	}
}

#topnav-links-column {
	margin-top: 10px;
}

.iq-navbar-contact-column {				
	padding: $iq-navbar-button-column-padding;
	flex-direction: $navbar-contact-column-flex-direction;
}

.iq-navbar-edit-column {				
    transition: $navbar-transition;
	padding: $iq-navbar-button-column-padding;
}

.navbar-logo-column {
	margin-top: 0;
    transition: $navbar-transition;
}

.environment {
    position: fixed;
    font-weight: 700;
    z-index: 1000;
    top: initial;
    right: 15px;
    display: block;
    bottom: 15px;
	
	&.PROD {
	
		display:none;
	}

	.stability-smarts {

		.card {
			border: 2px solid black;

			&.degraded {
				border-color: orange;

				.card-header {
					background: orange;
	
					button {
						color: #fff;
					}
				}
			}

			&.down {
				border-color: red;

				.card-header {
					background: red;

					button {
						color: #fff;
					}
				}
			}

			&.up {

				border-color: green;

				.card-header {
					background: green;

					button {
						color: #fff;
					}
				}
			}

			.card-header {
				padding: 0;
				background: #fff;

				h5 {
					margin-bottom: 0;
				}

				button {
					padding: 15px;
					text-align: left;
					width: 100%;

					.stability-name {
						display: none;
					}
				}
			}

			.card-body {

				padding: 0;

				.list-group-item {

					text-align: left;
					background: #fff;

					.fa-circle {
						color: green;
					}

					&.system-degraded {
						.fa-circle {
							color: orange;
						}
					}
	
					&.system-down {
	
						.fa-circle {
							color: red;
						}
					}
				}
			}

		}
	}
}

.referral-agency {
    margin-left: $referral-agency-margin-left;
    font-size: $referral-agency-font-size;
}

.navbar-main-content {
    .fa {
        padding-left: .4rem;
    }
}