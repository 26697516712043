@import "../variables/bridgedquote.variables.scss";

.bridged-quote {
    h1 {
        text-align: center;
    }

    .bridged-quote-message {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .quote-number {
        text-align: center;
        padding-bottom: 10px;
        color: $gray;
        font-size: $fontsize-standard;
    }

    .bridged-quote-card {
        height: 100%;
        padding-top: 15px;
        border-radius: 5px;
        box-shadow: $bridged-quote-card-box-shadow;

        .bridge-quote-card-head {
            display: flex;
            justify-content: space-between;
            border-bottom: $bridged-quote-card-head-border;
            margin-left: 5px;
            margin-right: 5px;
            padding-bottom: 10px;

            .bridge-quote-card-title {
                font-weight: 700;
                font-size: 19px;
            }

            .bridge-quote-card-complete-status{
                display: flex;
                align-self: center;
            }

            .summary-entity-icon{
                padding-left: 8px;
            }
        }

        .bridge-quote-card-body {
            margin-top: 14px;

            .bridged-quote-card-entity {
                padding-left: 5px;
                width: 100%;
                word-break: break-word;
            }

            .bridged-quote-card-entity-text {

                margin-left: -30px;

                .bridged-quote-card-headline{
                    font-weight: 700;
                    padding-left: 30px;
                }
    
                .bridged-quote-card-list {
                    text-align: left;
                }
    
                .bridged-quote-card-list-item {
                    font-style: italic;
                }
            }
        }
    }

    .bridged-quote-disclaimer {
        font-family: $font-family-sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        margin: 10px; 
    }
}