@import "../variables/driverthree.variables.scss";
#quote-driverthree-layout-form {
	
	label.col-form-label {
		font-family: $font-family-geogrotesque-semi-bold;
		font-size: $dynamic-repeatable-font-size;
		padding-top: $dynamic-repeatable-padding-top;
	}

	.NSATransferQuestion, .NSAHomeownerQuestion, .NSAAssociationDiscount, .NSATransferDiscount, .NSAHomeownerDiscount {
		legend {
			font-family: $font-family-geogrotesque-semi-bold;
			font-size: $dynamic-repeatable-font-size;
			text-align: left;
		}
	}
}