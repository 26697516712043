.modal-content {
    border-radius: 0;
    border: none;
    min-height: 300px;

    a {
        color: $modal-content-link-color;
        text-decoration-color: $modal-content-link-color;
        font-weight: bold;
    }

    .modal-header {
        padding: 0;
        background-color: $modal-header-bg-color;

        @include media-breakpoint-down(md) {
            h1 {
                font-size: 26px;
            }
        }

        //modal title
        .modal-title {
            margin: 0;
            padding-left: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            color: $primary;

            .fa-check {
                color: $dairyland-forest;
            }
        }

        //modal overrides
        .close {
            @extend .close-btn;
        }
    }

    .modal-body {
        label {
            @extend .typography-body-copy-bold;
        }

        .btn-link {
            @extend .typography-text-link-large;
        }

        hr {
            display: none;
        }
    }

    .modal-terms-conditions-container {
        padding-left: 22px;

        &.form-check {
            margin-top: 30px;
        }

        label.terms-conditions {
            padding-top: 0;
            margin-left: 10px;
        }
    }

    .bill-reminder-checkbox-addon:empty {
        &:before {
            content: "";
            position: relative;
            width: 20px;
        }

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .payment-button-container .btn {
        border-radius: 0;
    }

    .paperless-email-row .paperless-checkbox-addon {
        @include media-breakpoint-down(sm) {
            width: 1px;
        }
    }

    .modal-form .input-group {
        & > input,
        & > select {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: auto;
            }
        }
    }

    .container-fluid.cancel-submit-buttons {
        margin-top: 10px;
    }

    #quoteblock-modal-header {
        background-color: $dairyland-indigo;

        h1 {
            color: $dairyland-white;
        }
    }
}
