@import "../variables/summarystep.variables.scss";
.summary-step {
    h1 {
        margin-bottom: $summary-h1-margin-bottom;
    }
    .card:not(:last-child) {
        margin-bottom: $summary-card-margin-bottom;
    }
    .card-body {
        padding: $summary-card-body-padding;
        background: $summary-edit-entity-card-body-background;
        border: $summary-edit-entity-card-body-border;
        cursor: pointer;
    }
    .form-remove-entity {
        position: absolute;
        top: 0;
        right: 0;
    }
    .btn-remove-entity {
        color: $summary-delete-icon-color;
    }

    .summary-entity-icon,

    .add-entity-icon {
        color: $summary-entity-icon-color;
        font-size: $summary-entity-font-size;
        min-width: $summary-entity-min-width;
        margin-right: $summary-entity-margin-right;
        text-align: $summary-entity-text-align;
    }
    .add-entity-icon {
        color: $summary-add-icon-color;
        min-width: $summary-add-icon-min-width;
        margin-right: $summary-add-icon-margin-right;
    }
    .edit-entity-card {
        border-width: $summary-edit-entity-card-border-width;
        border-color: $summary-edit-entity-card-border-color;
        box-shadow: $summary-edit-entity-card-box-shadow;

        .card-body {
            padding-right: $summary-edit-entity-card-body-padding-right;
            overflow: hidden;
        }

        a {
            color: $summary-edit-entity-card-a-color;
        }
        .fa-trash {
            font-size: $summary-edit-entity-card-delete-icon-font-size;
        }
        button.card-body {
            &:focus {
                outline: $summary-entity-card-outline-focus
            }
        }
    }
    .add-entity-card {
        border-style: $summary-add-entity-card-border-style;
        font-family: $font-family-geogrotesque-semi-bold;

        button:focus {
            outline: $summary-entity-card-outline-focus
        }
    }

    .missing-info-entity-card {
        box-shadow: $summary-missing-info-entity-card-box-shadow;

        .card {
            color: $summary-missing-info-entity-card-color;
        }

        .summary-entity-icon {
            color: $summary-missing-info-entity-card-color;
        }
    }
}