body.quote .customer-quote-content .quote-container .coverage-form {

    h2 {
        font-size: 32px;
    }

    .coverage-header {
        font-family: $font-family-geogrotesque-semi-bold;
    }

	.coverage-term {
		padding-left: 0;
	}

    .icon-radio-fab {
        border: none;
        position: relative;
        top: 0px;
        font-size: 20px;
        @extend .template-inactive-radio-button-before;
    }

    .form-check-inline input:checked~.label-circle:after {
        display: none;
    }

    .form-check-inline input:checked~.icon-radio-fab {
        background: none;
        @extend .template-active-radio-button;

        &:before {
            opacity: 1;
            color: #2d6882;
        }
    }

    .coverage-category-label {
        color: $dairyland-asphalt;
    }
}

.coverage-container {
	
    // this hack is an attempt to wrap a coverage select element that has long option messages, this is the best
    // work-around I could come up with, so best of luck to you if you want to implement the fix in another way
    select#NSAPIPCovDedOptandWrkLs {
        height: 50px;
        width: 108%;
        white-space: break-spaces;
    }

    a[data-coveragetermcode="NSAPIPCovDedOptandWrkLs"] {
	    // important because this is easier to understand than a long nesting of css selectors for what is already a hack
        padding-top: 15px !important;
    }
	
	.always-displayed-help-message {
	    display: none;
	}

    .columnheader-yourpremium-content {
        margin-left: 24px;
    }

    .always-displayed-help-message {
        font-style: italic;
        margin-top: -20px;
        margin-bottom: 20px;
    }

    .continue-billplan-purchase,
    .findanagent-button-coverages {
        margin-top: 6px;
        margin-bottom: 15px;

        &:hover {
            color: $dairyland-active-copy-color;
        }
    }

    .select-coverage-premium-label-container {
        font-size: 12px;
    }

    .coverage-entity-label {
        text-align: center;
        color: $dairyland-asphalt;
        font-size: 20px;
        width: 100%;

        i {
            color: $dairyland-concrete;
        }
    }

    .vehicle-coverages .coverage-category-label {
        display: none;
    }

    .coverage-wrapper:not(.coverage-term-container):not(.readonly-coverage-selection) {
        display: flex;
    }

    .static-coverage-detail {
        border-radius: 5px;
        margin-bottom: 20px;
        box-shadow: 0 0 4px 0 $btn-radio-active-box-shadow;

        .coverage-header {
            padding-bottom: .5rem;
        }
    
        .offering-coverage-term-wrapper {
            padding-top: 2px;

            span {
                font-size: 1.15rem;
            }
        }

        .readonly-coverage-selection {
            padding-left: 0;
            padding-right: 0;
            border-top: 1px solid $gray-500;
            padding-top: 0;

            .coverage-row {
                padding-top: 6px;
            }
        }
    }

    .coverage-options {
        padding-top: 10px;
    }

    .form-group {
        margin-bottom: 0;
    }

    .coverage-term-input,
    .coverage-term-label {
        font-size: 1.15rem;
        color: $secondary;
        padding-bottom: 0;
        padding-top: 0;

        &.select-empty-value {
            color: $secondary;
        }
    }
    
    .coverage-term-input {
        margin-left: -4px;
    }

    .coverage-header {
        color: $primary;
    }

    .form-group:focus-within {

        label.coverage-header {
            color: $primary;
        }
        
        label.coverage-term {
            color: inherit;
        }
    }

    .edit-coverage-row {
        border-radius: 5px;
        margin-bottom: 20px;
        box-shadow: 0 0 4px 0 $btn-radio-active-box-shadow;
        
        .not-available {
            font-size: 1.15rem;
            color: $secondary;
            padding-left: 0;
        }
    }

    .first-group-by-field-code {

        .form-group {
            margin-bottom: 0;
        }

        .edit-coverage-row {
            clip-path: polygon(-10% -10%,110% 0%,110% 100%,-10% 100%);
            margin-bottom: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom: 0;
            overflow: auto;
        }
    }

    .last-group-by-field-code {
        
        .edit-coverage-row {
            clip-path: polygon(-10% 0%, 110% 0%, 100% 110%, 0% 110%);
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-top: 0;
        }
    }

    .sandwiched-group-by-field-code {

        .form-group {
            margin-bottom: 0;
        }

        .edit-coverage-row {
            clip-path: polygon(-10% 0%,110% 0,110% 100%,-10% 100%);
            margin-bottom: 0;
            border-radius: 0;
            border-bottom: 0;
            border-top: 0;
        }
    }

    .coverage-term-container {
        padding-left: 0;
        padding-right: 0;
    }
    
    .coverage-category-label {
        text-align: center;
        border: none;
        color: $dairyland-asphalt;
        width: 100%;

        i {
            color: $dairyland-concrete;
        }
    }

    .solo-group-by-field-code,
    .first-group-by-field-code {
        .coverage-term-container {
            border-top: 1px solid $gray-500;
            padding-top: 6px;
        }
    }

    .coverage-term-view-container,
    .offering-coverage-term-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .static-offering-coverage-term-label {
        order: 2;
    }
	
	.edit-link-container {
		width: 85px;
	}

    .edit-link-container,
    .edit-offering-coverage-link-container {
        order: 2;
        text-align: right;

        .edit-coverage-link,
        .edit-offering-coverage-link {
            padding-top: 0;
            color: $secondary;
            position: relative;
            background: transparent;
            border: 0;

            &:before {
                content: "\f040";
                font-family: 'FontAwesome';
                position: absolute;
                left: -18px;
            }

            &:focus-visible {
                outline: none;
            }
        }
    }

    .coverage-term-column {
        order: 2;
    }

    .tooltip-and-required {
        position: static;
        display: inline-block;
        padding-top: .3rem;
        padding-left: 10px;

        a {
            position: static;

            &:before {
                position: static;
            }
        }
    }
}

.coverage-container .edit-coverage-row .yourpremium-header {
    padding-left: 0;
}

.coverage-container #offering-accordion .offering-panel .reset-coverage-container .reset-coverage-link {
    text-decoration: none;
}

body.quote .customer-quote-content .quote-container .coverage-form .continue-billplan-purchase-container h2 {
    text-align: center;
    font-size: $header2-fontsize;
    font-weight: $header2-weight;
    color: $header2-color;
    padding-top: 0;
}