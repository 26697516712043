//
// Custom Mixins
// ------------
@mixin make-fancy-column($s, $s-md, $s-xl) {
	justify-content: center;
    flex-direction: column;
	@include make-col-ready();
	@include make-col($s);
	@include media-breakpoint-up(md) {
		@include make-col($s-md);
	}
	@include media-breakpoint-up(xl) {
		@include make-col($s-xl);
	}
}

//
// Application Overrides
//---------------

.alert.alert-danger {
	background-color: rgba($white, 0);
	border-color: rgba($white, 0);
}

.alert.alert-warning {
	background: transparent;
	border: 3px solid $dairyland-dusk;
	color: $dairyland-dusk;	
    padding: 16px;
	
	ul {
	    margin-bottom: 0;
	    padding-left: 25px;
	    list-style: none;
	}
	
	.icon-error {
	    position:absolute;
	    &:before {
	        font-family:"FontAwesome";
	        content: "\f071";
	    }
	}
}

body.post-bind {
	
		.groups-container {
			background-color: $quote-container-bg;
			padding-left: $quote-side-margin;
			padding-right: $quote-side-margin;
		}
	
		footer.footer {
					
			a {
			
				&.quote-phone-number {
					display: none;
				}
							
				&.bind-phone-number {						
					display: block;
				}
			}
		}
}

body.quote {
	&:not(.post-bind) {
		footer {
			
			&.footer {
				
				a {
					
					&.bind-phone-number {
						display: none;
					}
				}
			}	
		}
	}

	.hard-stop-container {
		.fail-message {
			font-weight: bold;
		}
	}

	.customer-quote-content {


		.form-header {
			h2, .h2 {
				@extend .typography-h1;
			}
		}
		
		.quote-container {

			padding-left: $quote-side-margin;
			padding-right: $quote-side-margin;

			h2, .h2, h4, .h4 {
				padding-top: $input-padding-y;
                font-size: $label-font-size-desktop;
                color: $quote-question-label-color;

				padding-left: 0px;

				&.tooltip-subheader {
					padding: 0 0 5px 0;
				}

				a {
					&.tooltips-link {
						top: 0;
    					display: inline;
					}
				}
			}
			
			.GaragingCity, .PriorCity {
				margin-top: 1rem;
			}

			.optional-indicator {
				@extend .typography-disclaimer;
				font-style: normal;
				bottom: 6px;
			}

			.required-fields-message {
				font-weight: normal;
			}

			.discount-field {
				.form-group {
					position: relative;
				}
			}

			.card-container {
				padding-bottom: 20px;
			}

			.summary-card {

				padding-left: 5px;
				padding-right: 5px;
				box-shadow: none;

				&.container-add-btn{
					border: none;

					.form-add-btn {
						.add-card {
							@extend .typography-text-link-large;
						}
					}
				}

				.link-edit-remove {
					span {
						@extend .typography-text-link-small-alt;
					}
					
					.btn-link {
						min-width: initial;
						padding: 0px;
					}
				}

				.form-remove-vehicle, .form-edit-driver{
					float: left;
					padding: 0 15px;
				}

				.form-edit-vehicle, .form-remove-driver {
					float: right;
					padding: 0 15px;
					text-align: center;
				}

				.form-edit-driver {
					padding-left: 10px;
				}

				.label-complete, .label-incomplete {
					@extend .typography-card-incomplete;
				}

				.info-sub-header, .info-header {
					@extend .typography-card-active;
				}

				.vehicle-counter {
					text-transform: uppercase;
				}
			}

			.entity-card:not(.active) {
				.summary-card:not(.active){

					.label-complete, .label-incomplete {
						@extend .typography-card-complete;
					}

					.info-sub-header, .info-header {
						@extend .typography-card-inactive;
					}

					.link-edit-remove {
						span {
							color: $dairyland-alt-link;
						}

						.fa-pencil,
						.fa-trash {
							color: $dairyland-alt-link;
						}
					}
				}
			}

			.save-and-exit {
				@extend .typography-text-link-large;
				color: #FFFFFF;
			}

			.disclaimer-container {
				h4, .h4, h2, .h2 {
					@extend .typography-disclaimer;
                    font-weight: normal;
				}

				a {
					@extend .typography-text-link-small;
				}
			}


			.coverage-form {

				.icon-radio-fab {
					background-color	: #FFFFFF;
					border-color		: $secondary;
				}

				.form-check-inline input:checked ~ .label-circle:after {
					color		: $white;
				}
				.form-check-inline input:checked ~ .icon-radio-fab {
					background-color	: $dairyland-asphalt;
					border-color		: $dairyland-asphalt;
				}

				.nav-item {
					padding-bottom: 8px;
				}

				.summary-card {
					border: 2px solid $secondary;

                    padding-top: 15px;
                    padding-bottom: 15px;

					&:hover {
						border: 2px solid $dairyland-asphalt;
						&:before {
							border: none;
						}
					}

					.tab-headline-not-customized {
						text-transform: uppercase;
					}

					label {
						@extend .typography-card-inactive;
					}

					&.active {
						border-width: 0px;

						label {
							@extend .typography-card-active;
						}
					}
				}

				.coverage-header {
					@extend .typography-body-copy;
				}

				.coverage-term {
					@extend .typography-body-copy-medium;
				}

				.coverage-category-label {
					@extend %typography-h2;
					margin-top: 17px;
				}
				.yourpremium-header {
					@extend .typography-column-header;
				}

				.coverage-billplan-container,
                .coverage-billplan-static-container {
					span.h1 {
						@extend .typography-h1;
					}

					p {
						@extend .typography-body-copy-small;
					}

					small {
						@extend .typography-disclaimer;
					}
				}

				.lien-coverage-suggestion {
					@extend .typography-warning-text;

					&.text-secondary-orange {
						color: $dairyland-brick;
					}
				}

				.static-coverage-detail {
                    .coverage-term-display-name {
                        font-family: $font-family-geogrotesque-semi-bold;
                    }
				}
			}
		}

		.icon-calendar {
			right: 25px;
			top: 10px;
		}

		.post-content-buttons {

			.add-entity-submit {
				@extend .typography-text-link-large;
			}
		}

		.reconcile-container {
			.drivers-container .drivers-nav-tabs-content{
				padding-bottom: 10px;
			}
		}
	}

	.tooltip-subheader.tooltip-and-required{
		a.tooltips-link{
			@extend .typography-text-link;
		}
	}

	

	//Moves tooltip arrow so it isn't sticking out too far
	.bs-popover-left {
		.arrow {
			right: calc((.4rem + #{$popover-border-width}) * -1);
		}
	}

	.datepicker.datepicker-dropdown {
		box-shadow: 0 0px 5px 1px rgba(54,29,93,0.6);
		border: none;
	}

	.page-error-container {
		.exception-image-content {

			.error-message {
				@extend .typography-h1;

				margin-bottom: 10px;
				text-transform: none;
			}
		}

		h2, .h2 {
			@extend %typography-h2;
			margin-bottom: 32px;
		}
	}
}

.input-group-prepend.input-group-text {
	border: none;
}

.additionaldrivers-tooltip {
	top: 15px;
}


.content {
    padding-bottom: 0px;
}

.footer .sentry-copyright {
    text-align: left;
    p {
        font-size: 12px;
    }
}

.tooltip-and-required.additionaldrivers-tooltip {
    top: 50px;
}

.parsley-errors-list {
	font-size: 14px;
}

//
// Icons
//------------------------------

.icon-questionmark:before {
	 font-family: "DairylandIcons";
	 content: "\e905";
	 color: $secondary;
	 font-size: $fontsize-small-sm;
}

.icon-calendar:before {
	font-family: "DairylandIcons";
	content: "\e924";
	color: $secondary;
}
.icon-phone:before {
	content: "\e93d";
    font-family: DairylandIcons;
}

.fa-times:before {
	font-family: "DairylandIcons";
	content: "\e903";
}

.icon-circleplus:before {
	font-family: "DairylandIcons";
	content: "\e90b";
}

//Icons for address standardization
.addresses-container {
	.fa-stack {
		height: 56px;
	}

	.fa-stack-2x {
		font-size: 56px;
	}
}

//
// Typography
//------------------------------
h1, .h1 {
    @extend .typography-h1;
}
.quote-sidebar {
     h4, .h4 {
        @extend .typography-h1;
     }
}

//
// Navigation Column Definitions
//------------------------------
.navbar {  
	
	.btn-iq-edit {
		visibility: hidden;
	}

	.save-and-exit {
	    max-width: 170px;
        background: transparent;
        border: none;
	    display:block;
	}
	
	.navbar-main {
		.navbar-logo-column {

			@include make-col-ready();
			@include make-col(6);
		}

		.navbar-contact-column {
			@include make-col-ready();
			@include make-col(6);
		}
	}
}

//
// Final Sale Bill Plans Column Definitions
// -------------------------------
.fancy-options-column {
	&.fancy-options-column-1 {
		@include make-fancy-column(1, 1, 1);

		/*
		 * HOKAY So... on a small screen the width is 30px and it has 15 px of padding for each side.
		 * boostrap doesn't like this and won't align the content side by side. So we remove 1 px from the left padding and it all looks good :)
		 * 
		 * HOKAY v2 padding right is causing issues with inline display. Setting to zero makes the fancy columns as fancy as they can be.
		 */
        padding-left: 14px;
        padding-right: 0px;
        justify-content: normal;
	}
	&.fancy-options-column-2 {
		@include make-fancy-column(11, 7, 7);
	}
	&.fancy-options-column-3 {
		@include make-fancy-column(12, 4, 4);
	}
}

//
//Inputs
// --------------------------------------------------
.form-control {
	border-color:$input-border-color;

	&:focus {
		border: 2px solid $input-border;
        color: $input-border;
		opacity: 1;
		box-shadow: none;
		padding-top: 11px;
	}

	&::-moz-placeholder {
	    color: $dairyland-ghosted-input-color;
	}
	&:-ms-input-placeholder {
		color: $dairyland-ghosted-input-color;
	} // Internet Explorer 10+
	&::-webkit-input-placeholder  {
		color: $dairyland-ghosted-input-color;
	} // Safari and Chrome
}

//
//Quote Container Background
// --------------------------------------------------
.quote-container {
	background-color: $quote-container-bg;
}

//
// Content Background Colors
//-------------------------
.bg-row-header {
  @include bg-variant(".bg-row-header", $gray-lighter);
}

//
// Text
//------------------------------
.text-secondary-orange {
	color: $dairyland-brick;
}
p {
    @extend .typography-body-copy;
}
.footer ul li {
	@extend .typography-footer-text;

	a {
	    text-decoration: none;
	}
}

::placeholder {
	@extend .typography-input-ghosted;
}

//
// Buttons
// -----------------------------------
.btn-primary,
.btn-secondary,
.btn-action {
    min-width: 100px;
}
.quote-phone-number .btn {
	font-size: 21px;
	padding-top: 5px;
}
.bind-phone-number .btn {
	font-size: 21px;
	padding-top: 5px;
}
.referral-phone-number .btn {
	font-size: 21px;
	padding-top: 5px;
}
.btn-link {
	@extend .typography-text-link;
}
.btn:not(.toggle-btn):not(.btn-show-summary):not(.btn-pl-phone) {
	font-family: $font-family-geogrotesque-semi-bold;
	box-shadow: none;
}
.btn-edit-link {
    font-size: 12px;
}
.close {
  opacity: 1;
}
.toggle-btn {
	padding-top: 10px;
	padding-bottom: 10px;
	border: none;
    box-shadow: 0px 0px 4px 0px $btn-radio-active-box-shadow;

	&.btn-secondary {
	    &:hover {
	        text-decoration:none;
	    }
        &:active,
        &.active {
            &:not(:disabled):not(.disabled) {
                border-color: $secondary;
            }
        }
   		&.focus {
            &:not(:disabled):not(.disabled):not(.active) {
                border-color: $dairyland-asphalt;
                color: $dairyland-asphalt;
            }
        }
	}
}
.btn-primary {
	@include button-variant($primary-button-bg,$primary-button-border);
	box-shadow: 0px 2px 2px #000;
}
.btn-danger {
	box-shadow: 0px 2px 2px #000;
}
.btn-success {
	@include button-variant($success-button-bg,$success-button-border);
	box-shadow: 0px 2px 2px #000;
}
.btn-action, .btn-primary {
  @include button-variant($btn-action-bg, $btn-action-border);
  box-shadow: none;

  &:hover:not(:disabled):not(.disabled),
  &:active:not(:disabled):not(.disabled),
  &:focus:not(:disabled):not(.disabled) {
	  background-color: $btn-action-hover-color;
  }
}
.btn-secondary-yellow{
	@include button-variant($secondary-yellow-button-bg, $secondary-yellow-button-border);
	box-shadow: none;
}

.btn.quote-submit {
	margin-top: 20px;
	margin-bottom: 20px;
}

.btn-payment{

	@include button-variant($btn-action-bg, $btn-action-border);
	box-shadow: 0px 2px 2px #000;
	border-radius: 2px;
	font-size: 19px;
	padding-top: 20px;
	padding-bottom: 20px;

	i {

		&.icon-circleplus {

			display: block;
			font-size: 44px;
		}
	}
}
button.close-btn {
    margin: 2px;
    opacity: 1;
    width: auto;
    height: auto;
    padding: 0.5rem;
    font-size: 17px;
    position: absolute;
    right: 0;
}


.customer-quote-container {
	.btn:disabled {
		background-color: $dairyland-concrete;
		border-color: $dairyland-concrete;
	}
}

body.quote.dairyland {
	.brand-header-title {
		display : none;
	}
}

#footer {

    .footer-save-and-exit-container,
    .footer-trustpilot-container {
        background: $primary;
    }
    
	.footer-trustpilot-container iframe {
		height: 200px;

		@include media-breakpoint-down(sm) {
			height: 315px;
		}
	}

	.btn-link {
		color: #FFFFFF;
		border-radius: 0;
	}

	.bg-primary {
        
		a {
			color: #FFFFFF;
		}
	}

    // allowlist the pages where save and exit should be presented to the user
    .save-and-exit {
        display: none;
    }
}

// allowlist the pages where save and exit and edit quote should be presented to the user
.quote-step-policyinfo, 
.quote-step-addressstandardization, 
.quote-step-vehicle, 
.quote-step-vehicleone,
.quote-step-vehicletwo,
.quote-step-vehiclesummary,
.quote-step-driverone,
.quote-step-driversummary,
.quote-step-drivertwo,
.quote-step-driverthree,
.quote-step-driverfour,
.quote-step-driverfourpowersports,
.quote-step-vehiclepowersports,
.quote-step-vehicleonepowersports,
.quote-step-vehicletwopowersports,
.quote-step-garagingaddressstandardization, 
.quote-step-driver, 
.quote-step-driverassignment, 
.quote-step-underwriting, 
.quote-step-scoreverification,
.quote-step-coverage,  
.quote-step-uwadditionalinfo, 
.quote-step-uwadditionalinfopowersports,
.quote-step-orderreports, 
.quote-step-reconcile, 
.quote-step-mvr, 
.quote-step-autoloss, 
.quote-step-accountclearance, 
.quote-step-confirmcoverages,
.quote-step-finalsale {
    &:not(.post-bind) {
        #footer {
            .save-and-exit {
                display: block;
            }
        }

		#quote-navigation {
			.btn-iq-edit {
				visibility: visible;
			}
		}
    }
}

//
// Loader/Spinner
//-----------------------------
.ui-icon-loading {
	background:none;
}
.spinner {
	width:50px;
	margin:0 auto;
}
.spinner.small div {
    height: 8px;
    width: 8px;
}

.spinner > div {
      width: 13px;
    height: 13px;
 background-color: $dairyland-light-copy;
 border-radius: 50%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

//-------------------------------
// Quote Mobile Headbar Steps
//-------------------------------
.quote-headbar {
    .label-circle {
        border-radius: 20px;
    }
    .label-circle-active {
        background: $dairyland-asphalt;
    }
    .label-circle-future {
        background: $dairyland-gray-copy;
    }
    .label-circle-complete {
        .fa-stack-2x {
            display:none;
        }
        .icon-checkmark {
            font-size:30px;
        }
    }
    .label-circle-future {
        .fa-stack-2x {
            display:none;
        }
    }
}

//-------------------------
// Sidebar Affix
//-------------------------
.quote-affix-column {
    position: static;
}
.confirmation-doc-header {
    padding-top: 10px;
	color: $primary;
	justify-content: center;
}
.btn-close-summary {
    top: 10px;
}
.print-all-button-container {
    margin-bottom: 15px;
	padding-bottom: 6px;
	border-bottom: $faq-card-border;
}
#bind-forms-documents {
    .form-link {
        &:hover {
            text-decoration: underline;
        }
    }
}
#purchaseflow-quote-accordion-xs-sm {
    .sidebar-item {
        margin-top: 0;
    }
}
#quote-affix-xs-sm {
    hr {
        margin: 0 0 5px 0;

        &.purchaseflow-divide {
            margin-top: 15px;
        }
    }

    .btn-edit-summary-link {
        width: 20%;
        text-align: right;
    }

    h4.affix-quote-number {
        font-size: 16px;
        font-weight: normal;
        padding: 0;
        margin: 0;
    }

    #purchaseflow-quote-container-xs-sm {
        li {
            margin-left: 12px;
            font-family:$font-family-geogrotesque-regular;
        }
        .affix-quote-summary {
            color: $dairyland-white;
            font-size: 36px;
        }
    }
}

//
// Terms and Conditions
//--------------------
.terms-and-conditions {
	@extend .typography-body-copy;
	background: 	$white;
	background-color: none;
	padding-bottom:	$terms-padding;
	font-weight:	$body-weight;
	color: 			$black;
	padding-top: $terms-padding;
    padding-right: $terms-padding;
    padding-left: $terms-padding;
	min-height: $terms-min-height;

	h1, .h1 {
		@extend .typography-h1;
		color: $dairyland-indigo;
	}
	h3, .h3 {
		@extend %typography-h3;
	}
	h4, .h4 {
		@extend .typography-h4;
		color: $dairyland-asphalt;
	}
}

//
// Payment Page
// -----------------
body.quote .finalsale-container .final-sale-section {
	width: 100%;

	h3, .h3, h2, .h2 {
		@extend %typography-h2;
	}

	.container-fluid {
		position: relative;
	}

	.show-less,
	.show-more {
		font-size: 20px;
		line-height: 26px;

		i {
			font-size: 12px;
		}
	}

	.summary-head p strong {
		color: $dairyland-asphalt;
		text-transform: uppercase;
		font-size: 16px;
	}

	#edit-payment-btn,
	#edit-auto-payment-btn {
		padding-left: 0;
	}

	input[name=eft-first-payment-different-method] {
		margin-left: -1.25rem;
	}

	.pay-today {
		h4,
        .h4,
		input,
		#pay-today-currency-sybmol {
			@extend %typography-h3;
		}
	}

	#AutomaticPaymentDraftDate {
		max-width: 200px;
		display: inline-block;
	}

	.payment-options-disclaimer-container ul {
		margin-bottom: 2rem;
	}

	.transaction-details-container strong {
		font-weight: inherit;
	}

	.payment-button-container #edit-payment-btn,
	.payment-button-container #edit-auto-payment-btn {
		@extend .typography-text-link-large;
	}

	.findanagent-partial-container {
		background-color: $white;
		padding-bottom: 0px;	
	}

	h5, .h5 {
		color: $primary;
		padding-top: 0px;
	}


	.btn-group[data-toggle=buttons] .list-group-item.btn.fancy-options-list-group-item,
	.btn-group.bill-plan-option-list-group .list-group-item.btn.fancy-options-list-group-item {
		border: 2px solid $secondary;
		color: $body-color;
		border-radius: 0;
		padding: .25rem .75rem;

		h3, .h3, h4, .h4 {
			@extend .typography-card-complete;
			color: $dairyland-asphalt;
			font-size: 1rem;

			&.term-sm {
				font-size: 14px;
			}
		}

		h5, .h5 {
			@extend .typography-disclaimer;
			color: $dairyland-asphalt;
		}

		.fa-stack {
			margin: 0;
		}

		.inputs .fa-circle-thin {
			color: $secondary;
            position: relative;
            font-size: 16px;
            text-align: left;
            margin-left: 0px;
		}

		.fancy-flex-container {
			height: 100%;
			flex-direction: column;
			justify-content: center;
		}

		.icon-checkmark {
			font-size: 1em;
			margin-left: 8px;
            box-shadow: none;
            font-size: 10px;
            text-align: left;
            margin-left: 0px;
			&:before {
				content: '\e90d';
				font-family: 'DairylandIcons';
			}
		}

		.term-sm-best-price-container {
			padding-bottom: 0.5rem;
		}

		.term-best-price {
			color: $secondary;

			.fa-stack {
				height: 1.5em;
				width: 1.5em;
				line-height: 1.5em;

				.fa-circle-thin {
					font-size: 1.5em;

					&:before {
						content: '\e928';
						font-family: 'DairylandIcons';
					}
				}
			}

			.fa.fa-dollar {
				display: none;
			}
		}

		&.active {
			background-color: $card-active-bg;
			border: none;

			&, a, .inputs .fa, .term-best-price {
				color: white;
			}

			h3, .h3, h4, .h4 {
				@extend .typography-card-incomplete;
				font-size: 1rem;

				&.term-sm {
					font-size: 14px;
				}
			}

			h5, .h5 {
				@extend .typography-disclaimer;
				color: $white;
			}

			.inputs {
				.fa-circle-thin {
					display: none;
				}
				.fa-stack {
					height: 12px;
					width: 12px;
				}
			}
		}

		&:before {
			display: none;
		}
	}
}

body .customer-quote-container#customer-bind-container {
	.customer-quote-content .quote-container {
		height: 100%;
	}

	#customer-bind-container .customer-quote-content .groups-container {

		padding-left: 15px;

		.quote-container {
			height: 100%;
		}
	}
}

// Confirmation Page

#customer-bind-container .confirmation-container {

	.confirmation-list-details {

		.list-group {

			.list-group-item {


				&.active {
					@extend %typography-h3;
					border-radius : 0;
					padding: 10px 15px;
					background-color: $dairyland-background-fill-color;
					border: none;
				}
			}
		}
	}

	.confirmation-panel-section {

		.card {
			margin: 16px 0;

			.card-header {
				color: $white;
			}

			.card-body {
				color: $primary;
				height: 100%;

				a.id-cards,
				a.policy-documents {
					@extend .typography-text-link;
					cursor: pointer;
				}
			}
		}
	}

	.confirmation-steps {

		.media {
			background-color: $dairyland-background-fill-color;
			border: 2px solid $dairyland-background-fill-color;

			.media-left.media-middle {
				align-self: center;
				background: none;
				padding: 6px;

				.label-circle {
					color: $primary;

					.circle-text {
						color: $white;
					}
				}

				.fa-circle-thin:before {
					content: '\f111';
				}
			}

			.media-body {
				background-color: $white;

				h4, .h4 {
                    &.media-heading {
                        @extend %typography-h3;
                        padding-top: 0;
                    }
				}

				.h5 {
					@extend .typography-body-copy;
					color: $dairyland-asphalt;
					font-weight: bold;
				}

				a {
					@extend .typography-text-link;
					font-size: inherit;
				}
			}
		}
	}

	.renters-insurance {
		background-color: $dairyland-foamy-milk-aka-bright-indigo-five-percent;
		i.fa {
			color: $dairyland-indigo;
		}
		h4, .h4 {
			@extend %typography-h3;
			color: $dairyland-asphalt;
		}
		a {
			@extend .typography-text-link;
			font-size: inherit;
		}
		p.disclaimer {
			@extend .typography-body-copy-small;
		}
	}

	h1 .confirmation-success-check {
		display: none;
	}

	.confirmation-disclaimer-content p {
		@extend .typography-body-copy-medium;
	}

	.confirmation-list-details .list-group .list-group-item.list-group-data-row {
		display: flex;
	}
}

#cancel-editpolicystartdate-mobile {
	padding: 11px 0px;
}

.form-check.mvr-mismatch-radio {
	padding-left: 0px;
}

.findanagent-container {
	
	a:hover {
	    text-decoration: underline;
	}
}

.findanagent-button.btn-secondary {
	background-color: transparent;
}

.continue-billplan-purchase-container {
	h3, .h3 {
		text-align: center;
	}
}

.findanagent-partial-container {
	
	background-color: $brand-gray-light;
    margin-top: 1.875rem;
    margin-bottom: 0.3125rem;
	
	h5, .h5 {
		font-size: 1.3125rem;
		padding-top: 1.4375rem;
		color: $dairyland-asphalt;
		font-family: $font-family-geogrotesque-semi-bold;
	}
	
	.findanagent-button-container {
		display: flex;
		justify-content: center;
		
		
	}
	
	.findanagent-button {
	  	font-family: $font-family-geogrotesque-semi-bold;
	  	font-size: 1.3125rem;
	  	line-height: 2.125rem;
	}
	
}

.quote-bottom-links {

	h5, .h5 {
		padding-bottom: 0.625rem;
	}

	.findanagent-partial-container {
    	margin-bottom: -0.9375rem;
    	padding-bottom: 1.875rem;
	}
}

.btn-secondary {
	background-color: $white;
	color: $dairyland-active-copy-color;
	font-weight: bold;
	border-width: 2px;
}

.findanagent-recalculate-container {
	padding-top: 30px;
	a:hover {
	    text-decoration: underline;
	}
}

.orderreports-container {
	.orderreports-form {
		#quote-orderreports-layout-form {
			a.tooltips-link {
				@extend .typography-text-link;
				font-size: 18px;
			}
			.incident-details {
				.incident-label, .incident-date {
					@extend .typography-field-label;
				}
			}
		}
	}
}

//
// Underwriting Page
// -----------------
.additionalinfo-vehicle-container .label-tooltip [aria-label*="InterestType"] {
	display: none;
}
.injected-variable {
    font-weight: bold;
}
.fa.icon-SEDAN:before {
  content: "\e968";
}
.fa.icon-TRUCK:before {
  content: "\e96a";
}
.fa.icon-SUV:before {
  content: "\e96b";
}
.fa.icon-VAN:before {
  content: "\e96c";
}
.fa.icon-MOTORCYCLE:before {
  content: "\e973";
}
.fa.icon-DIRTBIKE:before {
  content: "\e975";
}
.fa.icon-SNOWMOBILE:before {
  content: "\e978";
}
.fa.icon-ATV:before {
  content: "\e979";
}
.fa.icon-GOLFCART:before {
  content: "\e974";
}
.fa.icon-SCOOTER:before {
  content: "\e976";
}
.fa.icon-PERSON:before {
  content: "\e900";
}
.icon-questionmark:before {
  content: "\e969";
}
.fa.icon-selectcheck:before {
	content: "\e926";
}

//
// Urgent Notification Banner
//------------------------------
#urgent-notification-banner {
	color: $black;
    background-color: $dairyland-tint-sky-20;
	border-bottom: 1px solid $primary;
}

