@import "../variables/additionaldriver.variables.scss";

#quote-driver-layout-form {
	
    .LicenseNumber {

        label.col-form-label {
            font-family: $font-family-geogrotesque-semi-bold;
            font-size: $dynamic-repeatable-font-size;
            word-wrap: break-word;
        }

    }

}