@import "../variables/driverone.variables.scss";
#quote-driverone-layout-form {

    .effective-date-container {

        h2 {
            font-family: $effective-date-container-h2-font-family;
            font-weight: $effective-date-container-h2-font-weight;
            font-size: $effective-date-container-h2-font-size;
			padding-top: $effective-date-container-h2-padding-top;
        }
    }
}